import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import SupportHeader from "./SupportHeader";
import SupportContent from "./SupportContent";
import { useSelector } from "react-redux";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";

export const SupportPage = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const { faviconUrl } = useWhitelabel();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  return (
    <React.Fragment className="p-0">
      <Helmet title="Support">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <SupportHeader />
      <SupportContent />
    </React.Fragment>
  );
};

export default SupportPage;
