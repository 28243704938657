import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBillingProducts } from "../../redux/slices/billingProducts";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { Row, Col, Table } from "react-bootstrap";
import BillingProductsList from "./BillingProductsTable";
import { useTranslation } from "react-i18next";
import Loader from "../../components/spinners/Loader";
import NotyfContext from "../../contexts/NotyfContext";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const BillingProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billingProducts } = useSelector((state) => state.billingProducts);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { whitelabelLoading, faviconUrl, whitelabelUrl } = useWhitelabel();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchBillingProducts = async () => {
      await Promise.all([dispatch(getBillingProducts())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => setIsLoading(false));
    };

    if (activeUser.id) {
      fetchBillingProducts();
    }
  }, [activeUser, dispatch]);

  if (isLoading || whitelabelLoading) {
    return <Loader />;
  } else {
    if (billingProducts.length > 0) {
      return (
        <React.Fragment>
          <Helmet title="Billing Products">
            <link rel="icon" href={faviconUrl} />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">{t("billingProducts.title")}</h1>
            <Row>
              <Col md="12">
                <BillingProductsList data={billingProducts} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Helmet title="Billing Products">
            <link rel="icon" href={faviconUrl} />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">{t("billingProducts.title")}</h1>

            <Row>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("No data found.")}</th>
                    </tr>
                  </thead>
                </Table>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
};

export default BillingProducts;
