import React, { useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Form,
} from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SIMBarChart from "./SimBarChart";
import Single from "./SimSingle";
import SimStatistics from "./SimStatistics";
import SimWatchdogs from "./SimWatchdogTable";
import SimProducts from "./SimProductTable";
import { useDispatch, useSelector } from "react-redux";
import { getSim, getSimCellInfo } from "../../../../redux/slices/sims";
import Loader from "../../../../components/spinners/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NotyfContext from "../../../../contexts/NotyfContext";
import { useWhitelabel } from "../../../../contexts/WhitelabelContext";

const SIMProfile = () => {
  const location = useLocation();
  const { imsi } = useParams();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const [period, setPeriod] = useState("day");
  const notyf = useContext(NotyfContext);
  const sim = useSelector((state) => state.sims.sim);
  const [isLoading, setIsLoading] = useState(true);
  const cellInfo = useSelector((state) => state.sims.cellInfo);
  const network = useSelector((state) => state.sims.network);
  const { whitelabelLoading, faviconUrl, backgroundColor, fontColor } =
    useWhitelabel();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchSimInformation = async (id) => {
      try {
        const simData = await dispatch(getSim(imsi));

        if (simData.status === "Active" || simData.status === "Productive") {
          await dispatch(getSimCellInfo(imsi)); // Await this to ensure it finishes before setting loading to false
        }
      } catch (error) {
        notyf.open({
          type: "danger",
          message: error.message || "An error occurred",
          duration: 5000,
          dismissible: false,
          ripple: true,
          position: {
            x: "center",
            y: "top",
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (activeUser.id) {
      fetchSimInformation(activeUser.id);
    }
  }, [activeUser, imsi, dispatch]);

  if (isLoading) return <Loader />;
  return (
    <React.Fragment>
      <Helmet title={imsi}>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Container fluid className="p-0">
        <Row>
          <Col>
            <h1 className="h3 mb-3">{t("simProfile.simDetails")}</h1>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Form.Label className="me-3">
              {t("simProfile.selectPeriod")}:
            </Form.Label>
            <ButtonGroup className="shadow-sm">
              <Button
                key={1}
                variant="primary"
                onClick={() => setPeriod("day")}
                className={"day" === period ? "active" : null}
                style={{
                  "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": fontColor ?? "#ffffff",
                  border: "none",
                }}
              >
                {t("simProfile.daily")}
              </Button>
              <Button
                key={2}
                variant="primary"
                onClick={() => setPeriod("month")}
                className={"month" === period ? "active" : null}
                style={{
                  "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": fontColor ?? "#ffffff",
                  border: "none",
                }}
              >
                {t("simProfile.monthly")}
              </Button>
              <Button
                key={3}
                variant="primary"
                onClick={() => setPeriod("year")}
                className={"year" === period ? "active" : null}
                style={{
                  "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": fontColor ?? "#ffffff",
                  border: "none",
                }}
              >
                {t("simProfile.yearly")}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12" lg="4" xl="4">
            <Single sim={sim} cellInfo={cellInfo} network={network} />
          </Col>
          <Col md="12" lg="8" xl="8">
            <Row>
              <Col className="d-flex">
                <SimStatistics sim={sim} period={period} />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <SIMBarChart sim={sim} period={period} />
              </Col>
            </Row>
            <Row>
              <Col>
                <SimWatchdogs sim={sim} />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <SimProducts sim={sim} />
          </Col>
        </Row> */}
      </Container>
    </React.Fragment>
  );
};
export default SIMProfile;
