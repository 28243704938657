import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Card, Row, Spinner } from "react-bootstrap";
import {
  getDataVolumeComparison,
  getSessionComparison,
  getZeroSessionComparison,
} from "../../redux/slices/dataSessions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CountUp from "react-countup";
import { useRef } from "react";
import { getAlertComparison } from "../../redux/slices/alerts";
import {
  getAlertCounts,
  getDataSessionCounts,
  getDataVolumeCounts,
  getZeroSessionCounts,
} from "../../redux/slices/dashboard";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";

const Statistics = ({ type, reload }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDataVolumeLoading, setIsDataVolumeLoading] = useState(true);
  const [isDataSessionsLoading, setIsDataSessionsLoading] = useState(true);
  const [isZeroSessionsLoading, setIsZeroSessionsLoading] = useState(true);
  const [isAlertsLoading, setIsAlertsLoading] = useState(true);
  const dashboard = useSelector((state) => state.dashboard);
  const {
    data_volume,
    data_volume_difference_percentage,
    data_sessions,
    data_sessions_difference_percentage,
    alerts,
    alerts_difference_percentage,
    zero_sessions,
    zero_sessions_difference_percentage,
  } = dashboard;
  const notyf = useContext(NotyfContext);
  const [prevDataVolume, setPrevDataVolume] = useState(data_volume);
  const [prevDataSessions, setPrevDataSessions] = useState(data_sessions);
  const [prevZeroSessions, setPrevZeroSessions] = useState(zero_sessions);
  const [prevAlerts, setPrevAlerts] = useState(alerts);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const formatDataVolume = (volume) => {
    if (!volume && volume !== 0) {
      return (
        <>
          <span className="amount" style={{ fontSize: "28px" }}>
            0.00
          </span>
          <span className="ms-1 unit" style={{ fontSize: "16px" }}>
            Bytes
          </span>
        </>
      );
    }

    if (volume < 1000) {
      return (
        <>
          <span className="amount" style={{ fontSize: "28px" }}>
            {volume.toFixed(2)}
          </span>
          <span className="ms-1 unit" style={{ fontSize: "16px" }}>
            Bytes
          </span>
        </>
      );
    } else if (volume < 1000 * 1000) {
      return (
        <>
          <span className="amount" style={{ fontSize: "28px" }}>
            {(volume / 1000).toFixed(2)}
          </span>
          <span className="ms-1 unit" style={{ fontSize: "16px" }}>
            KB
          </span>
        </>
      );
    } else if (volume < 1000 * 1000 * 1000) {
      return (
        <>
          <span className="amount" style={{ fontSize: "28px" }}>
            {(volume / (1000 * 1000)).toFixed(2)}
          </span>
          <span className="ms-1 unit" style={{ fontSize: "16px" }}>
            MB
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="amount" style={{ fontSize: "28px" }}>
            {(volume / (1000 * 1000 * 1000)).toFixed(2)}
          </span>
          <span className="ms-1 unit" style={{ fontSize: "16px" }}>
            GB
          </span>
        </>
      );
    }
  };

  useEffect(() => {
    const fetchDataVolume = async () => {
      setPrevDataVolume(data_volume);
      setIsDataVolumeLoading(true);
      await dispatch(getDataVolumeCounts({ type: type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsDataVolumeLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchDataVolume();
    }
  }, [activeUser, type, reload]);

  useEffect(() => {
    const fetchDataSessions = async () => {
      setPrevDataSessions(data_sessions);
      setIsDataSessionsLoading(true);
      await dispatch(getDataSessionCounts({ type: type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsDataSessionsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchDataSessions();
    }
  }, [activeUser, type, reload]);

  useEffect(() => {
    const fetchZeroSessions = async () => {
      setIsZeroSessionsLoading(true);
      setPrevZeroSessions(zero_sessions);
      await dispatch(getZeroSessionCounts({ type: type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsZeroSessionsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchZeroSessions();
    }
  }, [activeUser, type, reload]);

  useEffect(() => {
    const fetchAlerts = async () => {
      setPrevAlerts(alerts);
      setIsAlertsLoading(true);
      await dispatch(getAlertCounts({ type: type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsAlertsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchAlerts();
    }
  }, [activeUser, type, reload]);

  return (
    <div className="w-100">
      <Row>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              {isDataVolumeLoading && (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    style={{
                      "--dynamic-spinner-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                    }}
                  />
                </div>
              )}
              {!isDataVolumeLoading && (
                <>
                  <Row>
                    <Col className="mt-0">
                      <h4 className="card-title">
                        {t("dashboard.dataVolume")}
                      </h4>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center">
                    <span className="h1 d-inline-block mt-3 mb-4">
                      <CountUp
                        start={0}
                        end={data_volume}
                        duration={2.75}
                        decimals={2}
                        redraw={data_volume !== prevDataVolume}
                        // formattingFn={formatDataVolume}
                      >
                        {({ countUpRef }) => (
                          <span>{formatDataVolume(data_volume)}</span>
                        )}
                      </CountUp>
                    </span>
                    <Badge
                      bg=""
                      className={`px-2 ms-2 ${
                        data_volume_difference_percentage > 0
                          ? "badge-soft-success me-2"
                          : "badge-soft-danger me-2"
                      }`}
                      style={{
                        height: "22px",
                      }}
                    >
                      {parseFloat(data_volume_difference_percentage).toFixed(2)}
                      %
                    </Badge>
                  </div>

                  <div className="mb-0">
                    {type === "day" && (
                      <span className="text-muted">
                        {t("dashboard.sinceYesterday")}
                      </span>
                    )}
                    {type === "month" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastMonth")}
                      </span>
                    )}
                    {type === "year" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastYear")}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              {isDataSessionsLoading && (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    style={{
                      "--dynamic-spinner-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                    }}
                  />
                </div>
              )}
              {!isDataVolumeLoading && (
                <>
                  <Row>
                    <Col className="mt-0">
                      <h4 className="card-title">
                        {t("dashboard.dataSessions")}
                      </h4>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center">
                    <span className="h1 d-inline-block mt-3 mb-4">
                      <CountUp
                        start={0}
                        end={data_sessions}
                        duration={2.75}
                        redraw={prevDataSessions !== data_sessions}
                      >
                        {({ countUpRef }) => (
                          <span className="amount" style={{ fontSize: "28px" }}>
                            {data_sessions}
                          </span>
                        )}
                      </CountUp>
                    </span>
                    <Badge
                      bg=""
                      className={`px-2 ms-2 ${
                        data_sessions_difference_percentage > 0
                          ? "badge-soft-success me-2"
                          : "badge-soft-danger me-2"
                      }`}
                      style={{
                        height: "22px",
                      }}
                    >
                      {parseFloat(data_sessions_difference_percentage).toFixed(
                        2
                      )}
                      %
                    </Badge>
                  </div>

                  <div className="mb-0">
                    {type === "day" && (
                      <span className="text-muted">
                        {t("dashboard.sinceYesterday")}
                      </span>
                    )}
                    {type === "month" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastMonth")}
                      </span>
                    )}
                    {type === "year" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastYear")}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              {isAlertsLoading && (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    style={{
                      "--dynamic-spinner-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                    }}
                  />
                </div>
              )}
              {!isAlertsLoading && (
                <>
                  <Row>
                    <Col className="mt-0">
                      <h4 className="card-title">{t("dashboard.alerts")}</h4>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center">
                    <span className="h1 d-inline-block mt-3 mb-4">
                      <CountUp
                        start={0}
                        end={alerts}
                        duration={2.75}
                        redraw={prevAlerts !== alerts || alerts === 0}
                      >
                        {({ countUpRef }) => (
                          <span
                            className="amount"
                            style={{ fontSize: "28px" }}
                            ref={countUpRef}
                          ></span>
                        )}
                      </CountUp>
                    </span>
                    <Badge
                      bg=""
                      className={`px-2 ms-2 ${
                        alerts_difference_percentage > 0
                          ? "badge-soft-success me-2"
                          : "badge-soft-danger me-2"
                      }`}
                      style={{
                        height: "22px",
                      }}
                    >
                      {parseFloat(alerts_difference_percentage).toFixed(2)}%
                    </Badge>
                  </div>
                  <div className="mb-0">
                    {type === "day" && (
                      <span className="text-muted">
                        {t("dashboard.sinceYesterday")}
                      </span>
                    )}
                    {type === "month" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastMonth")}
                      </span>
                    )}
                    {type === "year" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastYear")}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              {isZeroSessionsLoading && (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    style={{
                      "--dynamic-spinner-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                    }}
                  />
                </div>
              )}
              {!isZeroSessionsLoading && (
                <>
                  <Row>
                    <Col className="mt-0">
                      <h4 className="card-title">
                        {t("dashboard.zeroSessions")}
                      </h4>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center">
                    <span className="h1 d-inline-block mt-3 mb-4">
                      <CountUp
                        start={0}
                        end={zero_sessions}
                        duration={2.75}
                        redraw={prevZeroSessions !== zero_sessions}
                      >
                        {({ countUpRef }) => (
                          <span className="amount" style={{ fontSize: "28px" }}>
                            {zero_sessions}
                          </span>
                        )}
                      </CountUp>
                    </span>
                    <Badge
                      bg=""
                      className={`px-2 ms-2 ${
                        zero_sessions_difference_percentage > 0
                          ? "badge-soft-success me-2"
                          : "badge-soft-danger me-2"
                      }`}
                    >
                      {parseFloat(zero_sessions_difference_percentage).toFixed(
                        2
                      )}
                      %
                    </Badge>
                  </div>
                  <div className="mb-0">
                    {type === "day" && (
                      <span className="text-muted">
                        {t("dashboard.sinceYesterday")}
                      </span>
                    )}
                    {type === "month" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastMonth")}
                      </span>
                    )}
                    {type === "year" && (
                      <span className="text-muted">
                        {t("dashboard.sinceLastYear")}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
