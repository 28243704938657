import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import { Card, Form, Spinner, Row, Col } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import chroma from "chroma-js";
import NotyfContext from "../../contexts/NotyfContext";
import { getNetworkVolumeDistribution } from "../../redux/slices/dashboard";
import Loader from "../spinners/Loader";

const DataVolumeNetworkPieChart = ({ reload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [volumeNetworkDistribution, setVolumeNetworkDistribution] = useState(
    []
  );
  const [country, setCountry] = useState("Greece");
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [activeUser, setActiveUser] = useState({});
  const countries = countryList().getData();
  const [bgColor, setBgColor] = useState("#00acdc"); // assuming white as default
  const notyf = useContext(NotyfContext);
  const volume_by_network = useSelector(
    (state) => state.dashboard.volume_by_network
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user.id, impersonationUser.id]);

  useEffect(() => {
    setBgColor(whitelabel?.backgroundColor ?? "#00acdc");
  }, [activeUser]);

  const [scale, setScale] = useState("");

  useEffect(() => {
    if (bgColor) {
      setScale(() =>
        chroma.scale([
          chroma(bgColor).set("hsl.h", "-30"),
          bgColor,
          chroma(bgColor).set("hsl.h", "+30"),
        ])
      );
    }
  }, [bgColor]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const fetchVolumeNetworkDistribution = async () => {
      setIsLoading(true);
      await dispatch(getNetworkVolumeDistribution({ country: country }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchVolumeNetworkDistribution();
    }
  }, [activeUser, country, reload]);

  useEffect(() => {
    if (scale) {
      setColors(
        Array.from({ length: volume_by_network.length }, (_, i) =>
          scale(i / volume_by_network.length).hex()
        )
      );
    }
  }, [volume_by_network, activeUser.id, scale]);

  const data = {
    labels: volume_by_network.map((item) => item.network),
    datasets: [
      {
        data: volume_by_network.map((item) => item.count / 1000.0 / 1000.0),
        backgroundColor: colors,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let dataItem =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return (
            data.labels[tooltipItem.index] + ": " + dataItem.toFixed(2) + "MB"
          );
        },
      },
    },
  };

  return (
    <Card id="pieChart">
      <Card.Header>
        <h4 className="card-title">{t("dashboard.dataVolumePerNetwork")}</h4>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && volume_by_network.length === 0 && (
          <React.Fragment>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Select Country</Form.Label>
                <Form.Select
                  id="countrySelect"
                  name="country"
                  className="mb-5"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <div className="text-center">{t("No data found")}</div>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!isLoading && volume_by_network.length > 0 && (
          <React.Fragment>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t("dashboard.selectCountry")}</Form.Label>
                <Form.Select
                  id="countrySelect"
                  name="country"
                  className="mb-5"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <div className="chart chart-sm">
                <Pie data={data} options={options} />
              </div>
            </Row>
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataVolumeNetworkPieChart;
