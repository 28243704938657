import React, { useState, useEffect, useContext } from "react";
import { Card, Spinner, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import clicksims from "../../assets/img/clicksims.png";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const Loader = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  // const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const {
    whitelabelLoading,
    error,
    whitelabelUrl,
    backgroundColor,
    fontColor,
  } = useWhitelabel();

  return (
    <div
      className="spinner-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "50px",
        height: "100%",
        width: "100%",
      }}
    >
      <Image
        src={whitelabelUrl ? whitelabelUrl : clicksims}
        style={{ maxHeight: "300px", width: "auto" }}
        alt="Spinner"
      />
      <Spinner
        animation="border"
        role="status"
        style={{
          "--dynamic-spinner-color": backgroundColor ?? "#00acdc",
        }}
      />
    </div>
  );
};

export default Loader;
