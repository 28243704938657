import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { setSession, setImpersonationSession } from "../utils/jwt";
import countryList from "react-select-country-list";
import { useDispatch } from "react-redux";
import { loginUser, logoutUser } from "../redux/slices/user";
import impersonationUser, {
  impersonateUser,
  exitImpersonationMode,
} from "../redux/slices/impersonationUser";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const START_IMPERSONATION = "START_IMPERSONATION";
const STOP_IMPERSONATION = "STOP_IMPERSONATION";
const MFA_VERIFIED = "MFA_VERIFIED";
const SET_LOADING = "SET_LOADING";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isMfaAuthenticated: false,
  user: null,
  impersonationUser: null,
  loading: true,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isMfaAuthenticated: action.payload.isMfaAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        loading: false,
      };
    case SIGN_IN:
      return {
        ...state,
        isMfaAuthenticated: action.payload.isMfaAuthenticated,
        isAuthenticated: true,
        user: null,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isMfaAuthenticated: false,
        user: null,
      };
    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case START_IMPERSONATION:
      return {
        ...state,
        impersonationUser: action.payload.impersonationUser,
      };
    case STOP_IMPERSONATION:
      return {
        ...state,
        impersonationUser: null,
      };
    case MFA_VERIFIED:
      return {
        ...state,
        isMfaAuthenticated: action.payload.isMfaAuthenticated,
        user: action.payload.user,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const usrDispatch = useDispatch();
  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem("accessToken");

  //       if (accessToken) {
  //         setSession(accessToken);
  //         const userResponse = await axios.get(
  //           `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${accessToken}`,
  //             },
  //           }
  //         );

  //         const { ...user } = userResponse.data.data;

  //         if (user) {
  //           dispatch({
  //             type: INITIALIZE,
  //             payload: {
  //               isAuthenticated: true,
  //               user: user,
  //               isMfaAuthenticated: true,
  //             },
  //           });
  //           usrDispatch(loginUser(user));
  //         } else {
  //           dispatch({
  //             type: INITIALIZE,
  //             payload: {
  //               isAuthenticated: false,
  //               user: null,
  //               isMfaAuthenticated: false,
  //             },
  //           });
  //         }
  //       } else {
  //         dispatch({
  //           type: INITIALIZE,
  //           payload: {
  //             isAuthenticated: false,
  //             user: null,
  //             isMfaAuthenticated: false,
  //           },
  //         });
  //         setSession(null);
  //       }
  //     } catch (err) {
  //       dispatch({
  //         type: INITIALIZE,
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //           isMfaAuthenticated: false,
  //         },
  //       });
  //       setSession(null);
  //       usrDispatch(logoutUser());
  //     }
  //   };

  //   initialize();
  // }, [usrDispatch]);

  // useEffect(() => {
  //   const getImpersonationUser = async () => {
  //     try {
  //       const impersonatedToken =
  //         window.localStorage.getItem("impersonationToken");
  //       if (impersonatedToken) {
  //         setImpersonationSession(impersonatedToken);
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${impersonatedToken}`,
  //             },
  //           }
  //         );
  //         const { ...impersonationUser } = response.data.data;
  //         if (impersonationUser) {
  //           dispatch({
  //             type: START_IMPERSONATION,
  //             payload: {
  //               impersonationUser: impersonationUser,
  //             },
  //           });
  //           usrDispatch(impersonateUser(impersonationUser));
  //         }
  //       } else {
  //         dispatch({
  //           type: STOP_IMPERSONATION,
  //         });
  //         stopImpersonation();
  //         setImpersonationSession(null);
  //       }
  //     } catch (error) {
  //       dispatch({
  //         type: STOP_IMPERSONATION,
  //       });
  //       stopImpersonation();
  //       setImpersonationSession(null);
  //     }
  //   };

  //   getImpersonationUser();
  // }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const impersonatedToken =
          window.localStorage.getItem("impersonationToken");
        if (impersonatedToken) {
          setImpersonationSession(impersonatedToken);
          const response = await axios.get(
            `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user`,
            {
              headers: {
                Authorization: `Bearer ${impersonatedToken}`,
              },
            }
          );
          const { ...impersonationUser } = response.data.data;
          if (impersonationUser) {
            dispatch({
              type: START_IMPERSONATION,
              payload: {
                impersonationUser: impersonationUser,
              },
            });
            usrDispatch(impersonateUser(impersonationUser));
          }
        }
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          setSession(accessToken);
          const userResponse = await axios.get(
            `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const { ...user } = userResponse.data.data;

          if (user) {
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: user,
                isMfaAuthenticated: true,
              },
            });
            usrDispatch(loginUser(user));
          }
        } else {
          // Step 3: No token found, reset state
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (error) {
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      } finally {
        setTimeout(() => {
          dispatch({ type: SET_LOADING, payload: { loading: false } });
        }, 500);
      }
    };

    initialize();
  }, []);

  const signIn = async (emailOrUsername, password, rememberMe) => {
    try {
      const data = {
        usernameOrEmail: emailOrUsername,
        password: password,
        rememberMe: rememberMe,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/signin`,
        data
      );
      const { token, isMfaEnabled } = response.data.data;
      if (token) {
        setSession(token);
        if (isMfaEnabled) {
          dispatch({
            type: SIGN_IN,
            payload: {
              isMfaAuthenticated: false,
              user: null,
            },
          });
          return Promise.resolve(response.data.message);
        } else {
          const userResponse = await axios.get(
            `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const { ...user } = userResponse.data.data;
          usrDispatch(loginUser(user));
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              isMfaAuthenticated: true,
              user: user,
            },
          });
          return Promise.resolve(response.data.message);
        }
      }
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  const signOut = async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("Unauthorized");
    try {
      stopImpersonation();
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/signout`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      await Promise.all([usrDispatch(logoutUser())]).then(() => {
        setSession(null);
        dispatch({ type: SIGN_OUT });
        return Promise.resolve(response.data.message);
      });
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  const startImpersonation = async (id) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("Unauthorized");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/impersonate/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const { impersonationToken, impersonationUser } = response.data.data;
      dispatch({
        type: START_IMPERSONATION,
        payload: {
          impersonationUser: impersonationUser,
        },
      });
      setImpersonationSession(impersonationToken);
      usrDispatch(impersonateUser(impersonationUser));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  const stopImpersonation = async () => {
    dispatch({ type: STOP_IMPERSONATION });
    await usrDispatch(exitImpersonationMode());
    window.localStorage.removeItem("impersonationUser");
  };

  const signUp = async (
    fullName,
    address,
    city,
    country,
    postcode,
    telephoneNumber,
    mobileNumber,
    email,
    occupation,
    company,
    tin,
    username,
    password,
    photo
  ) => {
    const options = countryList().getData();
    const abbreviation = country;
    const countryRecord = options.find((c) => c.value === abbreviation);
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("country", countryRecord.label);
    formData.append("postcode", postcode);
    formData.append("telephoneNumber", telephoneNumber);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("occupation", occupation);
    formData.append("company", company);
    formData.append("tin", tin);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("photo", photo);
    await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/signup`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const verifyMfa = async (code) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      if (!accessToken) return Promise.reject("No access token");
      const data = {
        mfaCode: code,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/verify`,
        data,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      dispatch({
        type: MFA_VERIFIED,
        payload: {
          user: response.data.data,
          isMfaAuthenticated: true,
        },
      });
      setSession(accessToken);
      usrDispatch(loginUser(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch({
        type: MFA_VERIFIED,
        payload: {
          isMfaAuthenticated: false,
        },
      });
      return Promise.reject(error.message);
    }
  };

  const resetPassword = async (email) => {
    try {
      const data = {
        email: email,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/forgot-password`,
        data
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  const setInitialPassword = async ({ password, token }) => {
    try {
      const data = {
        password: password,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/change-password/${token}`,
        { password }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        startImpersonation,
        stopImpersonation,
        verifyMfa,
        setInitialPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
