import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../redux/slices/admins";
import { deleteTenant, getTenants } from "../../redux/slices/tenants";
import { deleteManager, getManagers } from "../../redux/slices/managers";
import { deleteCustomer, getCustomers } from "../../redux/slices/customers";

import {
  Card,
  Col,
  Row,
  Table,
  CloseButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { formatDistanceToNow } from "date-fns";
import { FaCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CrmConfig from "./configuration/CrmConfig";
import { ExternalLink } from "react-feather";
import useAuth from "../../hooks/useAuth";
import defaultUser from "../../assets/img/avatars/default-user.png";
import EditEntityModal from "./modals/EditEntityModal";
import NotyfContext from "../../contexts/NotyfContext";
import { deleteSubaccount } from "../../redux/slices/subaccounts";
import UpdatePrivilegeModal from "./modals/UpdatePrivilegeModal";
import ToggleWhitelabelingModal from "./modals/ToggleWhitelabelingModal";
import AssignOffersModal from "./modals/AssignOffersModal";
import { initializeSims } from "../../redux/slices/sims";
import AssignPermissionsModal from "./modals/AssignPermissionsModal";
import { initializeAlerts } from "../../redux/slices/alerts";
import { sendWelcomeMail } from "../../redux/slices/mail";
import BillingSettingsModal from "./modals/BillingSettingsModal";
import { useTranslation } from "react-i18next";

export default function Single(props) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { startImpersonation } = useAuth();
  const [showEditEntity, setShowEditEntity] = useState(false);
  const [clientToEdit, setClientToEdit] = useState({});
  const [clientToUpgrade, setClientToUpgrade] = useState({});
  const [showPrivilegeModal, setShowPrivilegeModal] = useState(false);
  const [showWhitelabelingModal, setShowWhitelabelingModal] = useState(false);
  const [showAssignOffersModal, setShowAssignOffersModal] = useState(false);
  const [awaitingSimInit, setAwaitingSimInit] = useState(false);
  const [showAssignPermissionsModal, setShowAssignPermissionsModal] =
    useState(false);
  const [showBillingPreferencesModal, setShowBillingPreferencesModal] =
    useState(false);
  const { t } = useTranslation();
  const [awaitingAlertInit, setAwaitingAlertInit] = useState(false);
  const notyf = useContext(NotyfContext);
  const { resetPassword } = useAuth();
  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const dispatch = useDispatch();
  const [client, setClient] = useState({
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telephone: "",
    mobileNumber: "",
    email: "",
    occupation: "",
    company: "",
    tin: "",
    username: "",
    privilege: "",
    activities: {},
    serverDetails: {},
  });
  const [showClientOffCanvas, setShowClientOffCanvas] = useState(false);

  useEffect(() => {
    if (!props.shownClient) {
      return;
    }
    setClient({
      id: props.shownClient?.id,
      fullName: props.shownClient?.fullName,
      address: props.shownClient?.address,
      city: props.shownClient?.city,
      country: props.shownClient?.country,
      postcode: props.shownClient?.postcode,
      telephone: props.shownClient?.telephoneNumber,
      mobileNumber: props.shownClient?.mobileNumber,
      email: props.shownClient?.email,
      occupation: props.shownClient?.occupation,
      company: props.shownClient?.company,
      tin: props.shownClient?.tinNumber,
      username: props.shownClient?.username,
      privilege: props.shownClient.role
        ? props.shownClient?.role.toUpperCase()
        : "",
      activities: props.shownClient.activities,
      image: props.shownClient?.image?.imagePath,
      whitelabelingEnabled:
        props.shownClient.role !== "customer" &&
        props.shownClient.role !== "subaccount"
          ? props.shownClient?.whitelabelingEnabled
          : "",
      privileges: props.shownClient?.privileges ?? [],
      serverDetails: props.shownClient?.serverDetails ?? {},
    });
  }, [props.shownClient]);
  const keys = Object.keys(client?.activities ?? []);
  const activityRecords = [];
  for (let i = 0; i < keys.length; i++) {
    activityRecords.push(client.activities[keys[i]]);
  }
  activityRecords.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  useEffect(() => {
    if (user) {
      switch (user.privilege) {
        case "ADMIN":
          dispatch(getAdmins(user.id));
          dispatch(getManagers(user.id));
          dispatch(getTenants(user.id));
          dispatch(getCustomers(user.id));
          break;
        case "TENANT":
          dispatch(getManagers(user.id));
          dispatch(getCustomers(user.id));
          break;
        case "MANAGER":
          dispatch(getCustomers(user.id));
          break;
        default:
          break;
      }
    }
  }, [dispatch, user]);

  return (
    <Card className="w-100 flex-fill">
      <Card.Header>
        <Row>
          <Col xxl="6">
            <Card.Title className="mb-0">{client.fullName}</Card.Title>
          </Col>
          <Col xxl="6">
            <CloseButton
              className="float-end"
              onClick={() => {
                props.setSingleIsShown(false);
                setTimeout(function () {
                  props.setOpen(false);
                }, 200);
              }}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row className="g-0">
          <Col sm="3" xl="12" className="col-xxl-3 text-center">
            <img
              src={
                client.image
                  ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${client.image}`
                  : defaultUser
              }
              width="64"
              height="64"
              className="rounded-circle mt-2"
              alt="Angelica Ramos"
            />
          </Col>
        </Row>

        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{t("crm.fullName")}</th>
              <td>{client.fullName}</td>
            </tr>
            {client.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>{t("crm.address")}</th>
                <td>{client.address}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>{t("crm.city")}</th>
                <td>{client.city}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>{t("crm.country")}</th>
                <td>{client.country}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>{t("crm.postcode")}</th>
                <td>{client.postcode}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>{t("crm.telephone")}</th>
                <td>{client.telephone}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>{t("crm.mobile")}</th>
                <td>{client.mobileNumber}</td>
              </tr>
            )}
            <tr>
              <th>{t("crm.email")}</th>
              <td>{client.email}</td>
            </tr>
            {client.privilege !== "SUBACCOUNT" && client.privilege !== "ADMIN" && (
              <tr>
                <th>{t("crm.occupation")}</th>
                <td>{client.occupation}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && client.privilege !== "ADMIN" && (
              <tr>
                <th>{t("crm.company")}</th>
                <td>{client.company}</td>
              </tr>
            )}
            {client.privilege !== "SUBACCOUNT" && client.privilege !== "ADMIN" && (
              <tr>
                <th>{t("crm.tin")}</th>
                <td>{client.tin}</td>
              </tr>
            )}
            <tr>
              <th>{t("crm.role")}</th>
              <td>{client.privilege}</td>
            </tr>
            {activeUser.role !== "subaccount" && (
              <tr>
                <th style={{ verticalAlign: "middle" }}>Actions</th>
                <td>
                  <Dropdown className="me-3">
                    <Dropdown.Toggle
                      as="a"
                      className="actions-icon nav-icon dropdown-toggle p-0"
                    >
                      <FaCog size={16} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {client.privilege !== "ADMIN" && (
                        <Dropdown.Item
                          key="edit"
                          onClick={() => {
                            const data = {
                              id: client.id,
                              fullName: client.fullName,
                              address:
                                client.privilege !== "subaccount"
                                  ? client.address
                                  : "",
                              city:
                                client.privilege !== "subaccount"
                                  ? client.city
                                  : "",
                              country:
                                client.privilege !== "subaccount"
                                  ? client.country
                                  : "",
                              postcode:
                                client.privilege !== "subaccount"
                                  ? client.postcode
                                  : "",
                              telephone:
                                client.privilege !== "subaccount"
                                  ? client.telephone
                                  : "",
                              mobileNumber:
                                client.privilege !== "subaccount"
                                  ? client.mobileNumber
                                  : "",
                              email: client.email,
                              occupation:
                                client.privilege !== "admin" &&
                                client.privilege !== "subaccount"
                                  ? client.occupation
                                  : "",
                              company:
                                client.privilege !== "admin" &&
                                client.privilege !== "subaccount"
                                  ? client.company
                                  : "",
                              tinNumber:
                                client.privilege !== "admin" &&
                                client.privilege !== "subaccount"
                                  ? client.tin
                                  : "",
                              username: client.username,
                            };
                            setClientToEdit(data);
                            setShowEditEntity(true);
                          }}
                        >
                          {t("crm.edit")}
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        key="delete"
                        onClick={async () => {
                          const id = client.id;
                          if (client.privilege === "TENANT") {
                            await dispatch(deleteTenant(id))
                              .then(() => {
                                notyf.open({
                                  type: "success",
                                  message: "Tenant deleted successfully",
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              })
                              .catch((error) => {
                                notyf.open({
                                  type: "danger",
                                  message: error,
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              });
                          } else if (client.privilege === "MANAGER") {
                            await dispatch(deleteManager(id))
                              .then(() => {
                                notyf.open({
                                  type: "success",
                                  message: "Manager deleted successfully",
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              })
                              .catch((error) => {
                                notyf.open({
                                  type: "danger",
                                  message: error,
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              });
                          } else if (client.privilege === "CUSTOMER") {
                            await dispatch(deleteCustomer(id))
                              .then(() => {
                                notyf.open({
                                  type: "success",
                                  message: "Customer deleted successfully",
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              })
                              .catch((error) => {
                                notyf.open({
                                  type: "danger",
                                  message: error,
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              });
                          } else if (client.privilege === "SUBACCOUNT") {
                            await dispatch(deleteSubaccount(id))
                              .then(() => {
                                notyf.open({
                                  type: "success",
                                  message: "Subaccount deleted successfully",
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              })
                              .catch((error) => {
                                notyf.open({
                                  type: "danger",
                                  message: error,
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              });
                          }
                        }}
                      >
                        {t("crm.delete")}
                      </Dropdown.Item>

                      {(client.privilege === "TENANT" ||
                        client.privilege === "MANAGER" ||
                        client.privilege === "CUSTOMER") && (
                        <React.Fragment>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            key="billing"
                            onClick={() => {
                              setClientToEdit(client);
                              setShowBillingPreferencesModal(true);
                            }}
                          >
                            {t("crm.billing")}
                          </Dropdown.Item>
                        </React.Fragment>
                      )}
                      {user.role === "admin" &&
                        client.privilege !== "CUSTOMER" &&
                        client.privilege !== "SUBACCOUNT" && (
                          <Dropdown.Item
                            key="whitelabel"
                            onClick={() => {
                              setClientToEdit(client);
                              setShowWhitelabelingModal(true);
                            }}
                          >
                            {t("crm.whitelabeling")}
                          </Dropdown.Item>
                        )}
                      {client.privilege != "SUBACCOUNT" && (
                        <Dropdown.Item
                          key="privilege"
                          onClick={() => {
                            setClientToUpgrade(client);
                            setShowPrivilegeModal(true);
                          }}
                        >
                          {t("crm.role")}
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        key="offers"
                        onClick={() => {
                          setClientToEdit(client);
                          setShowAssignOffersModal(true);
                        }}
                      >
                        {t("crm.offers")}
                      </Dropdown.Item>
                      {client.privilege === "SUBACCOUNT" && (
                        <Dropdown.Item
                          key="permissions"
                          onClick={() => {
                            setClientToEdit(client);
                            setShowAssignPermissionsModal(true);
                          }}
                        >
                          {t("crm.permissions")}
                        </Dropdown.Item>
                      )}

                      {(user.role === "admin" || user.role === "tenant") &&
                        !impersonationUser.id && (
                          <React.Fragment>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              key="impersonate"
                              onClick={async () => {
                                try {
                                  await startImpersonation(Number(client.id));
                                  navigate("/");
                                } catch (error) {}
                              }}
                            >
                              {t("crm.impersonate")}
                            </Dropdown.Item>
                          </React.Fragment>
                        )}

                      {activeUser.role === "admin" && (
                        <Dropdown.Item
                          key="sendWelcomeEmail"
                          onClick={async () => {
                            await sendWelcomeMail(client.id)
                              .then(() => {
                                notyf.open({
                                  type: "success",
                                  message: "Welcome email sent successfully",
                                  duration: 5000,
                                  ripple: true,
                                  dismissible: false,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              })
                              .catch((error) => {
                                notyf.open({
                                  type: "danger",
                                  message: error,
                                  duration: 5000,
                                  dismissible: false,
                                  ripple: true,
                                  position: {
                                    x: "center",
                                    y: "top",
                                  },
                                });
                              });
                          }}
                        >
                          {t("crm.sendWelcomeEmail")}
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        key="resetPassword"
                        onClick={async () => {
                          await resetPassword(client.email)
                            .then(() => {
                              notyf.open({
                                type: "success",
                                message: "Welcome email sent successfully",
                                duration: 5000,
                                ripple: true,
                                dismissible: false,
                                position: {
                                  x: "center",
                                  y: "top",
                                },
                              });
                            })
                            .catch((error) => {
                              notyf.open({
                                type: "danger",
                                message: error,
                                duration: 5000,
                                dismissible: false,
                                ripple: true,
                                position: {
                                  x: "center",
                                  y: "top",
                                },
                              });
                            });
                        }}
                      >
                        {t("crm.resetPassword")}
                      </Dropdown.Item>

                      {client.privilege !== "SUBACCOUNT" &&
                        activeUser.role === "admin" && (
                          <React.Fragment>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              key="sims"
                              onClick={async () => {
                                setAwaitingSimInit(true);
                                await dispatch(initializeSims(client.id))
                                  .then(() => {
                                    notyf.open({
                                      type: "success",
                                      message: "SIMs initialized successfully",
                                      duration: 5000,
                                      dismissible: false,
                                      ripple: true,
                                      position: {
                                        x: "center",
                                        y: "top",
                                      },
                                    });
                                  })
                                  .catch((error) => {
                                    notyf.open({
                                      type: "danger",
                                      message: error,
                                      duration: 5000,
                                      dismissible: false,
                                      ripple: true,
                                      position: {
                                        x: "center",
                                        y: "top",
                                      },
                                    });
                                  })
                                  .finally(() => {
                                    setAwaitingSimInit(false);
                                  });
                              }}
                            >
                              {t("crm.initializeSIMs")}
                            </Dropdown.Item>
                          </React.Fragment>
                        )}
                      {client.privilege !== "SUBACCOUNT" &&
                        activeUser.role === "admin" && (
                          <Dropdown.Item
                            key="alerts"
                            onClick={async () => {
                              setAwaitingAlertInit(true);
                              await initializeAlerts(client.id)
                                .then(() => {
                                  notyf.open({
                                    type: "success",
                                    message: "Alerts initialized successfully",
                                    duration: 5000,
                                    dismissible: false,
                                    ripple: true,
                                    position: {
                                      x: "center",
                                      y: "top",
                                    },
                                  });
                                })
                                .catch((error) => {
                                  notyf.open({
                                    type: "danger",
                                    message: error,
                                    duration: 5000,
                                    dismissible: false,
                                    ripple: true,
                                    position: {
                                      x: "center",
                                      y: "top",
                                    },
                                  });
                                })
                                .finally(() => {
                                  setAwaitingAlertInit(false);
                                });
                            }}
                          >
                            {t("crm.initializeAlerts")}
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {awaitingSimInit && (
                    <Spinner animation="border" role="status" className="ms-3">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <EditEntityModal
          showEditEntity={showEditEntity}
          setShowEditEntity={setShowEditEntity}
          entity={clientToEdit}
          type={
            props?.shownClient?.role?.charAt(0).toUpperCase() +
            props?.shownClient?.role?.slice(1)
          }
        />
        <UpdatePrivilegeModal
          showPrivilegeModal={showPrivilegeModal}
          setShowPrivilegeModal={setShowPrivilegeModal}
          entityUser={clientToUpgrade}
        />
        <ToggleWhitelabelingModal
          showToggleWhitelabelingModal={showWhitelabelingModal}
          setShowToggleWhitelabelingModal={setShowWhitelabelingModal}
          entityUser={clientToEdit}
        />
        <AssignOffersModal
          showAssignOffersModal={showAssignOffersModal}
          setShowAssignOffersModal={setShowAssignOffersModal}
          entityUser={clientToEdit}
        />
        <AssignPermissionsModal
          showAssignPermissionsModal={showAssignPermissionsModal}
          setShowAssignPermissionsModal={setShowAssignPermissionsModal}
          subaccount={clientToEdit}
        />
        <BillingSettingsModal
          showBillingSettingsModal={showBillingPreferencesModal}
          setShowBillingSettingsModal={setShowBillingPreferencesModal}
          client={clientToEdit}
        />
      </Card.Body>
    </Card>
  );
}
