import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Image } from "react-bootstrap";
import VerifyMfa from "../../components/auth/VerifyMfa";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const VerifyMfaPage = () => {
  const dispatch = useDispatch();
  const currentUrl = window.location.hostname;
  const { whitelabelUrl } = useWhitelabel();

  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <Image
                src={whitelabelUrl}
                alt=""
                className="img-fluid"
                width="150"
                height="150"
              />
            </div>
            <VerifyMfa />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default VerifyMfaPage;
