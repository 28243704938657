import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Tab, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Notifications from "./Notifications";
import Navigation from "./Navigation";
import GeneralSettings from "./GeneralSettings";
import Privacy from "./Privacy";
import Loader from "../../../components/spinners/Loader";
import AccountSettings from "./AccountSettings";
import ProfileImageSettings from "./ProfileImageSettings";
import WhitelabelSettings from "./WhitelabelSettings";
import Billing from "./Billing";
import InvoiceSettings from "./InvoiceSettings";
import { useTranslation } from "react-i18next";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const generalPreferences = useSelector(
    (state) => state.preferences.generalPreferences
  );
  const [activeTab, setActiveTab] = useState("#General");
  const { whitelabelLoading, faviconUrl } = useWhitelabel();

  const isMfaAvailable =
    activeUser.role !== "subaccount" ||
    (activeUser.role === "subaccount" &&
      activeUser.privileges.some(
        (privilege) => privilege.name === "ToggleMFA"
      ));

  const isWhitelabelAvailable =
    (activeUser.role !== "subaccount" && activeUser.whitelabelingEnabled) ||
    (activeUser.role === "subaccount" &&
      activeUser.whitelabelingEnabled &&
      activeUser.privileges.some(
        (privilege) => privilege.name === "UpdateWhitelabel"
      ));

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  if (isLoading || whitelabelLoading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Settings">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Tab.Container
        fluid
        className="p-0"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        <h1 className="h3 mb-3">{t("settings.title")}</h1>
        <Row>
          <Col md="3" xl="2">
            <Navigation
              isWhitelabelAvailable={isWhitelabelAvailable}
              isMfaAvailable={isMfaAvailable}
            />
          </Col>
          <Col md="9" xl="10">
            <Tab.Content>
              <Tab.Pane eventKey="#General">
                <GeneralSettings
                  generalPreferences={generalPreferences}
                  activeTab={activeTab}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="#Account">
                <AccountSettings activeTab={activeTab} />
              </Tab.Pane>
              <Tab.Pane eventKey="#ProfileImage">
                <ProfileImageSettings activeTab={activeTab} />
              </Tab.Pane>
              {isWhitelabelAvailable && (
                <Tab.Pane eventKey="#Whitelabel">
                  <WhitelabelSettings activeTab={activeTab} />
                </Tab.Pane>
              )}
              {isMfaAvailable && (
                <Tab.Pane eventKey="#Privacy">
                  <Privacy activeTab={activeTab} />
                </Tab.Pane>
              )}
              <Tab.Pane eventKey="#Notifications">
                <Notifications activeTab={activeTab} />
              </Tab.Pane>
              {(activeUser.role === "tenant" ||
                activeUser.role === "manager" ||
                activeUser.role === "customer") && (
                <Tab.Pane eventKey="#Billing">
                  <Billing activeTab={activeTab} />
                </Tab.Pane>
              )}
              {(activeUser.role === "admin" ||
                activeUser.role === "tenant" ||
                activeUser.role === "manager") && (
                <Tab.Pane eventKey="#Invoice">
                  <InvoiceSettings activeTab={activeTab} />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </React.Fragment>
  );
};

export default Settings;
