import React from "react";
import { Container } from "react-bootstrap";
import SearchBar from "./SupportSearch";
import { useSelector } from "react-redux";

export const SupportHeader = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  return (
    <div
      className="hero"
      style={{
        "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
        "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
        border: "none",
      }}
    >
      <div className="overlay"></div>
      <Container>
        <p
          className="support-welcome-text"
          style={{
            "--dynamic-font-color": !impersonationUser.id
              ? user.fontColor
              : impersonationUser.fontColor,
          }}
        >
          Welcome to ClickSims Help Center
        </p>
        <h1
          className="support-question"
          style={{
            "--dynamic-font-color": !impersonationUser.id
              ? user.fontColor
              : impersonationUser.fontColor,
          }}
        >
          What can we help you find?
        </h1>
        <SearchBar />
      </Container>
    </div>
  );
};

export default SupportHeader;
