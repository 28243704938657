import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveSims,
  getAverageDataVolume,
} from "../../redux/slices/dashboard";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";

const AdditionalStatistics = ({ type, reload }) => {
  const { t } = useTranslation();
  const dashboard = useSelector((state) => state.dashboard);
  const { average_data_volume, active_sims } = dashboard;
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isAverageDataVolumeLoading, setIsAverageDataVolumeLoading] =
    useState(true);
  const [isActiveSimsLoading, setIsActiveSimsLoading] = useState(true);
  const [prevActiveSims, setPrevActiveSims] = useState(active_sims);
  const [prevAverageDataVolume, setPrevAverageDataVolume] =
    useState(average_data_volume);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchActiveSims = async () => {
      setIsActiveSimsLoading(true);
      setPrevActiveSims(active_sims);
      await dispatch(getActiveSims())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsActiveSimsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchActiveSims();
    }
  }, [activeUser, type, reload]);

  useEffect(() => {
    const fetchAverageDataVolume = async () => {
      setIsAverageDataVolumeLoading(true);
      setPrevAverageDataVolume(average_data_volume);
      await dispatch(getAverageDataVolume())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsAverageDataVolumeLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchAverageDataVolume();
    }
  }, [activeUser, type, reload]);

  return (
    <Row>
      <Col className="d-flex" lg={12} xl={6}>
        <Card className="flex-fill">
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            {isAverageDataVolumeLoading && <Loader />}
            {!isAverageDataVolumeLoading && (
              <>
                <Row>
                  <Col className="mt-0">
                    <h4 className="card-title">
                      {t("dashboard.averageVolume")}
                    </h4>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-3 mb-4 text-center">
                  <CountUp
                    start={0}
                    end={parseFloat(average_data_volume / 1000.0 / 1000.0)}
                    duration={2.75}
                    decimals={2}
                    redraw={average_data_volume !== prevAverageDataVolume}
                  />
                  MB
                </span>
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col className="d-flex" lg={12} xl={6}>
        <Card className="flex-fill">
          <Card.Body className="d-flex justify-content-center align-items-center flex-column">
            {isActiveSimsLoading && <Loader />}
            {!isActiveSimsLoading && (
              <>
                <Row>
                  <Col className="mt-0">
                    <h4 className="card-title">{t("dashboard.activeSims")}</h4>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-3 mb-4">
                  <CountUp
                    start={0}
                    end={active_sims}
                    duration={2.75}
                    redraw={active_sims !== prevActiveSims}
                  />
                </span>
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AdditionalStatistics;
