import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import * as Yup from "yup";
import { Formik } from "formik";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";
import NotyfContext from "../../contexts/NotyfContext";
import { Button, Card, Form, Image, Spinner } from "react-bootstrap";
import clicksims from "../../assets/img/clicksims.png";
import { Helmet } from "react-helmet-async";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const InitialPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = window.location.hostname;
  const { setInitialPassword } = useAuth();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const {
    whitelabelLoading,
    whitelabelUrl,
    faviconUrl,
    backgroundColor,
    fontColor,
  } = useWhitelabel();

  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  if (whitelabelLoading) {
    return (
      <React.Fragment>
        <Card>
          <Card.Body>
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <Spinner animation="border" role="status" />
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Initial Password">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <Image
                src={whitelabelUrl}
                alt=""
                className="img-fluid"
                width="150"
                height="150"
              />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  await setInitialPassword({ password: values.password, token })
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Password has been set successfully",
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                        duration: 5000,
                      });
                      navigate("/auth/sign-in");
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                        duration: 5000,
                      });
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={Boolean(
                            touched.password && errors.password
                          )}
                        />
                        {!!touched.password && (
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                        />
                        {!!touched.confirmPassword && (
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          type="submit"
                          variant="primary"
                          style={{
                            "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                            "--dynamic-font-color": fontColor ?? "#ffffff",
                            border: "none",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default InitialPassword;
