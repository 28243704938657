import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CloseButton,
  Button,
  Table,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ActivateSimModal from "./modals/ActivateSimModal";
import { ExternalLink } from "react-feather";
import SimsMap from "./SimsMap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuspendSimModal from "./modals/SuspendSimModal";
import ResumeSimModal from "./modals/ResumeSimModal";
import TerminateSimModal from "./modals/TerminateSimModal";
import { formatDistanceToNow } from "date-fns";
import { useSelector } from "react-redux";

function Single(props) {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openTestModeModal, setOpenTestModeModal] = useState(false);
  const openResumeModalRef = useRef(null);
  const [activityRecords, setActivityRecords] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You lack necessary permissions, please contact your parent account owner.
    </Tooltip>
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (
      props.shownSim &&
      props.shownSim.activities &&
      props.shownSim.activities.length > 0
    ) {
      const newActivityRecords = [...props.shownSim.activities].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivityRecords(newActivityRecords);
    }
  }, [props.shownSim]);

  if (props.shownSim && props.shownSim.id) {
    return (
      <>
        <Card>
          <Card.Header>
            <Row>
              <Col xxl="6">
                <Card.Title className="mb-0">SIM {t("Information")}</Card.Title>
              </Col>
              <Col xxl="6">
                <CloseButton
                  className="float-end"
                  onClick={() => {
                    props.setSingleIsShown(false);
                    setTimeout(function () {
                      props.setOpen(false);
                    }, 200);
                  }}
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {activeUser.role !== "admin" && (
              <Row className="g-0">
                <strong>Actions</strong>
                <div className="d-flex flex-wrap mt-2" id="simsActionsPanel">
                  {(props.shownSim.status === "OnStock" ||
                    props.shownSim.status === "TestProductive") && (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          activeUser.role === "subaccount" &&
                          !activeUser.privileges.some(
                            (privilege) => privilege.name === "ActivateSIM"
                          ) ? (
                            renderTooltip
                          ) : (
                            <></>
                          ) // Use null instead of <></>
                        }
                      >
                        <span className="d-inline-block">
                          <Button
                            variant="success"
                            className="me-2 mb-1 px-2"
                            id="simsActionActivate"
                            style={{
                              "--dynamic-bg-color":
                                whitelabel?.backgroundColor ?? "#00acdc",
                              "--dynamic-font-color":
                                whitelabel?.fontColor ?? "#fff",
                              border: "none",
                            }}
                            onClick={async () => {
                              setOpenActivateModal(true);
                            }}
                            disabled={
                              activeUser.role === "subaccount" &&
                              !activeUser.privileges.some(
                                (privilege) => privilege.name === "ActivateSIM"
                              )
                            }
                          >
                            <span> {t("Activate")}</span>
                          </Button>
                        </span>
                      </OverlayTrigger>
                    </>
                  )}
                  {props.shownSim.status === "Productive" && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        activeUser.role === "subaccount" &&
                        !activeUser.privileges.some(
                          (privilege) => privilege.name === "SuspendSIM"
                        ) ? (
                          renderTooltip
                        ) : (
                          <></>
                        )
                      }
                    >
                      <span className="d-inline-block">
                        <Button
                          variant="warning"
                          className="me-2 mb-1 px-2"
                          id="simsActionSuspend"
                          onClick={async () => {
                            setOpenSuspendModal(true);
                          }}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                          disabled={
                            activeUser.role === "subaccount" &&
                            !activeUser.privileges.some(
                              (privilege) => privilege.name === "SuspendSIM"
                            )
                          }
                        >
                          <span> {t("Suspend")}</span>
                        </Button>
                      </span>
                    </OverlayTrigger>
                  )}
                  {(props.shownSim.status === "Suspended" ||
                    props.shownSim.status === "AutoSuspended") && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        activeUser.role === "subaccount" &&
                        !activeUser.privileges.some(
                          (privilege) => privilege.name === "ResumeSIM"
                        ) ? (
                          renderTooltip
                        ) : (
                          <></>
                        )
                      }
                    >
                      <span className="d-inline-block">
                        <Button
                          variant="primary"
                          className="me-2 mb-1 px-2"
                          id="simsActionResume"
                          onClick={async () => {
                            setOpenResumeModal(true);
                          }}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                          disabled={
                            activeUser.role === "subaccount" &&
                            !activeUser.privileges.some(
                              (privilege) => privilege.name === "ResumeSIM"
                            )
                          }
                        >
                          <span> {t("Resume")}</span>
                        </Button>
                      </span>
                    </OverlayTrigger>
                  )}
                  {(props.shownSim.status === "Productive" ||
                    props.shownSim.status === "Suspended" ||
                    props.shownSim.status === "AutoSuspended") && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        activeUser.role === "subaccount" &&
                        !activeUser.privileges.some(
                          (privilege) => privilege.name === "TerminateSIM"
                        ) ? (
                          renderTooltip
                        ) : (
                          <></>
                        )
                      }
                    >
                      <span className="d-inline-block">
                        <Button
                          variant="danger"
                          className="me-2 mb-1 px-2"
                          id="simsActionTerminate"
                          onClick={async () => {
                            setOpenTerminateModal(true);
                            await new Promise((resolve) => {
                              const interval = setInterval(() => {
                                if (!openResumeModalRef.current) {
                                  clearInterval(interval);
                                  resolve();
                                }
                              }, 500);
                            });
                          }}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                          disabled={
                            activeUser.role === "subaccount" &&
                            !activeUser.privileges.some(
                              (privilege) => privilege.name === "TerminateSIM"
                            )
                          }
                        >
                          <span> {t("Terminate")}</span>
                        </Button>
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.shownSim.status === "OnStock" && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        activeUser.role === "subaccount" &&
                        !activeUser.privileges.some(
                          (privilege) => privilege.name === "TestModeSIM"
                        ) ? (
                          renderTooltip
                        ) : (
                          <></>
                        )
                      }
                    >
                      <span className="d-inline-block">
                        <Button
                          variant="info"
                          className="me-2 mb-1 px-2"
                          id="simsActionTest"
                          onClick={() => setOpenTestModeModal(true)}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                          disabled={
                            activeUser.role === "subaccount" &&
                            !activeUser.privileges.some(
                              (privilege) => privilege.name === "TestModeSIM"
                            )
                          }
                        >
                          {t("Test Mode")}
                        </Button>
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              </Row>
            )}
            <Table size="sm" className="my-2">
              <tbody>
                <tr>
                  <th>{t("Usage")}</th>
                  <td>
                    {props.shownSim.totalBytes !== null
                      ? `${parseFloat(props.shownSim.totalMBytes).toFixed(3)}MB`
                      : "0.00MB"}
                  </td>
                </tr>
                <tr>
                  <th>{t("IP Address")}</th>
                  <td>
                    {props.shownSim.ipAddress !== null
                      ? props.shownSim.ipAddress
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>IMEI</th>
                  <td>
                    {props.shownSim.imei !== null ? props.shownSim.imei : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Status")}</th>
                  <td>
                    <Badge
                      bg={
                        props.shownSim.status === "Productive"
                          ? "primary"
                          : props.shownSim.status === "Active"
                          ? "success"
                          : props.shownSim.status === "Suspended"
                          ? "warning"
                          : props.shownSim.status === "Terminated"
                          ? "danger"
                          : props.shownSim.status === "OnStock"
                          ? "light"
                          : props.shownSim.status === "Deleted"
                          ? "danger"
                          : props.shownSim.status === "TestProductive"
                          ? "primary"
                          : props.shownSim.status === "AutoSuspended"
                          ? "warning"
                          : null
                      }
                    >
                      {t(props.shownSim.status)}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <th>{t("Visit Profile")}</th>
                  <td>
                    <span
                      onClick={() => {
                        navigate(`/sims/profile/${props.shownSim.imsi}`);
                      }}
                    >
                      <ExternalLink size={18} />
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>

            <hr />
            <strong>{t("Map")}</strong>
            <SimsMap
              lat={parseFloat(props.shownSim?.latestCellInfo?.latitude)}
              lon={parseFloat(props.shownSim?.latestCellInfo?.longitude)}
              className="my-3"
            />
            <Table size="sm">
              <tbody>
                <tr>
                  <th>{t("Provider")}</th>
                  <td>{props.shownSim.sku}</td>
                </tr>
                <tr>
                  <th>ICCID</th>
                  <td>{props.shownSim.iccid}</td>
                </tr>
                <tr>
                  <th>MSISDN</th>
                  <td>
                    {props.shownSim.msisdn !== ""
                      ? props.shownSim.msisdn
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>IMSI</th>
                  <td>
                    {props.shownSim.imsi !== "" ? props.shownSim.imsi : "N/A"}
                  </td>
                </tr>
                {props.shownSim.offer && (
                  <tr>
                    <th>Offer</th>
                    <td>
                      {props.shownSim.offer.name !== ""
                        ? props.shownSim.offer.name
                        : "N/A"}
                    </td>
                  </tr>
                )}
                <tr>
                  <th>{t("Area")}</th>
                  <td>
                    {props.shownSim?.latestCellInfo?.area
                      ? props.shownSim?.latestCellInfo?.area
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Cell")}</th>
                  <td>
                    {props.shownSim?.latestCellInfo?.cell
                      ? props.shownSim?.latestCellInfo?.cell
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Longitude")}</th>
                  <td>
                    {props.shownSim?.latestCellInfo?.longitude
                      ? props.shownSim?.latestCellInfo?.longitude
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Latitude")}</th>
                  <td>
                    {props.shownSim?.latestCellInfo?.latitude
                      ? props.shownSim?.latestCellInfo?.latitude
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Cell Range")}</th>
                  <td>
                    {props.shownSim?.latestCellInfo?.range
                      ? props.shownSim?.latestCellInfo?.range
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Unit")}</th>
                  <td>
                    {props.shownSim?.latestCellInfo?.unit
                      ? props.shownSim?.latestCellInfo?.unit
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />

            <strong>{t("Activity")}</strong>

            <ul className="timeline mt-2">
              {props.shownSim.simActivity.map((activityElement, index) => (
                <React.Fragment key={index}>
                  {activityElement.type === "ACTIVATE" && (
                    <li className="timeline-item">
                      <strong>Activated</strong>
                      <span className="float-end text-muted text-sm">
                        {activityElement.createdAt}
                      </span>
                      <p>{activityElement.description}</p>
                    </li>
                  )}
                  {activityElement.type === "SUSPEND" && (
                    <li className="timeline-item">
                      <strong>Suspended</strong>
                      <span className="float-end text-muted text-sm">
                        {activityElement.createdAt}
                      </span>
                      <p>{activityElement.description}</p>
                    </li>
                  )}
                  {activityElement.type === "RESUME" && (
                    <li className="timeline-item">
                      <strong>Resumed</strong>
                      <span className="float-end text-muted text-sm">
                        {activityElement.createdAt}
                      </span>
                      <p>{activityElement.description}</p>
                    </li>
                  )}
                  {activityElement.type === "MODIFY" && (
                    <li className="timeline-item">
                      <strong>Modified</strong>
                      <span className="float-end text-muted text-sm">
                        {activityElement.createdAt}
                      </span>
                      <p>{activityElement.description}</p>
                    </li>
                  )}
                  {activityElement.type === "TERMINATE" && (
                    <li className="timeline-item">
                      <strong>Terminated</strong>
                      <span className="float-end text-muted text-sm">
                        {activityElement.createdAt}
                      </span>
                      <p>{activityElement.description}</p>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <ActivateSimModal
              openActivateModal={openActivateModal}
              selectedSims={[props.shownSim]}
              setOpenActivateModal={setOpenActivateModal}
              clearSelectedRows={props.clearSelectedRows}
            />
            <SuspendSimModal
              openSuspendModal={openSuspendModal}
              sims={[props.shownSim]}
              setOpenSuspendModal={setOpenSuspendModal}
              clearSelectedRows={props.clearSelectedRows}
            />
            <ResumeSimModal
              openResumeModal={openResumeModal}
              sims={[props.shownSim]}
              setOpenResumeModal={setOpenResumeModal}
              clearSelectedRows={props.clearSelectedRows}
            />
            <TerminateSimModal
              openTerminateModal={openTerminateModal}
              sims={[props.shownSim]}
              setOpenTerminateModal={setOpenTerminateModal}
              clearSelectedRows={props.clearSelectedRows}
            />
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return <Card></Card>;
  }
}

export default Single;
