import React, { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Col, Spinner } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

import { useDispatch, useSelector } from "react-redux";
import { getDistribution } from "../../redux/slices/dataSessions";
import NotyfContext from "../../contexts/NotyfContext";
import { getDistributionByMonth } from "../../redux/slices/dashboard";
import Loader from "../spinners/Loader";

const DataVolumeBarChart = ({ period, reload }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [activeUser, setActiveUser] = useState({});
  const { t } = useTranslation();
  const [type, setType] = useState("data_volume");
  const [isLoading, setIsLoading] = useState(true);
  const [barData, setBarData] = useState([]);
  const notyf = useContext(NotyfContext);
  const by_month = useSelector((state) => state.dashboard.by_month);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user.id, impersonationUser.id]);

  useEffect(() => {
    const fetchDistribution = async () => {
      setIsLoading(true);
      await dispatch(getDistributionByMonth({ type: type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchDistribution();
    }
  }, [activeUser, type, reload]);

  function calculateData(item, type) {
    switch (type) {
      case "data_volume":
        return parseFloat(item.totalBytes / 1000.0 / 1000.0).toFixed(2);
      case "data_sessions":
        return item.dataSessions;
      case "zero_sessions":
        return item.dataSessions;
      case "alerts":
        return item.alerts;
      default:
        return 0;
    }
  }

  function hexToRgba(hex, opacity) {
    const bigint = parseInt(hex.replace("#", ""), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const data = {
    labels: by_month.map((item) => item?.period),
    datasets: [
      {
        label: "Number of Sims",
        type: "line",
        data: by_month.map((item) => item.numberOfSims),
        borderColor: whitelabel?.backgroundColor ?? "#00acdc",

        backgroundColor: "transparent",
        borderWidth: 2,
        pointBackgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        pointBorderColor: whitelabel?.fontColor ?? "#ffffff",
        fill: false, // No area fill under the line
        order: 1,
      },
      {
        label:
          type === "data_volume"
            ? "Data Volume"
            : type === "data_sessions"
            ? "Data Sessions"
            : type === "alerts"
            ? "Alerts"
            : type === "zero_sessions"
            ? "Zero Sessions"
            : "Extra Charge",
        backgroundColor: hexToRgba(
          whitelabel?.backgroundColor ?? "#00acdc",
          0.75
        ),
        borderColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBackgroundColor: hexToRgba(
          whitelabel?.backgroundColor ?? "#00acdc",
          0.75
        ),
        hoverBorderColor: whitelabel?.backgroundColor ?? "#00acdc",
        data: by_month.map((item) => calculateData(item, type)),
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        order: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },

          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header className="d-flex align-items-center justify-content-between">
        <h4 className="card-title mb-0">
          {type === "data_volume"
            ? t("dashboard.dataVolume")
            : type === "data_sessions"
            ? t("dashboard.dataSessions")
            : type === "alerts"
            ? t("dashbpard.alerts")
            : type === "zero_sessions"
            ? t("dashboard.zeroSessions")
            : // : type === "extra_charge"
              // ? t("Extra Charge")
              null}
        </h4>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("data_volume")}>
                {t("dashboard.dataVolume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("dashboard.alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("data_sessions")}>
                {t("dashboard.dataSessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zero_sessions")}>
                {t("dashboard.zeroSessions")}
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setType("extra_charge")}>
                {t("Extra Charge")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body className="d-flex">
        <Col>
          {!isLoading && by_month.length === 0 && (
            <div className="d-flex justify-content-center align-items-center">
              {t("No data available")}
            </div>
          )}
          {!isLoading && by_month.length > 0 && (
            <div className="align-self-center w-100">
              <div className="chart">
                <Bar data={data} options={options} />
              </div>
            </div>
          )}
          {isLoading && <Loader />}
        </Col>
      </Card.Body>
    </Card>
  );
};

export default DataVolumeBarChart;
