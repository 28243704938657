import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import NotyfContext from "../../../../contexts/NotyfContext";
import { getSimDataSessions } from "../../../../redux/slices/sims";
import Loader from "../../../../components/spinners/Loader";

const SimDataSessionsModal = ({
  showSessionModal,
  setShowSessionModal,
  id,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [sessions, setSessions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const sim_sessions = useSelector((state) => state.sims.sim_sessions);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchSimSessions = async () => {
      await dispatch(getSimDataSessions({ id, page: page, pageSize }))
        .then((response) => {
          setNumberOfPages(response.pages);
        })
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && id && showSessionModal) {
      fetchSimSessions();
    }
  }, [activeUser, id, page, pageSize, showSessionModal]);

  return (
    <Modal
      show={showSessionModal}
      size="xl"
      backdrop="static"
      centered
      scrollable
      onHide={() => setShowSessionModal(false)}
    >
      <Modal.Header>
        <Modal.Title>{t("simProfile.sessionModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isLoading && sim_sessions.length === 0 && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <p>No Data Sessions</p>
          </div>
        )}
        {!isLoading && sim_sessions.length > 0 && (
          <>
            <Table responsive hover scrollable>
              <thead>
                <tr>
                  <th>{t("ID")}</th>
                  <th>{t("simProfile.sessionModal.openDate")}</th>
                  <th>{t("simProfile.sessionModal.closeDate")}</th>
                  <th>{t("simProfile.sessionModal.consumption")}</th>
                  <th>{t("simProfile.sessionModal.roundedBytes")}</th>
                  <th>{t("simProfile.sessionModal.operator")}</th>
                  <th>{t("simProfile.sessionModal.cell")}</th>
                  <th>{t("simProfile.sessionModal.imei")}</th>
                </tr>
              </thead>
              <tbody>
                {sim_sessions.map((session) => (
                  <tr key={session.id}>
                    <td>{session.id}</td>
                    <td>{session.openDate}</td>
                    <td>{session.closeDate}</td>
                    <td>{session.totalBytes}</td>
                    <td>{session.roundedBytes}</td>
                    <td>{session.network}</td>
                    <td>{session.cellId}</td>
                    <td>{session.imei}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row>
              <Col md="8">
                <span className="mx-2">
                  {t("table.page")}{" "}
                  <strong>
                    {page} {t("table.of")} {numberOfPages}
                  </strong>
                </span>
                <span className="ms-3 me-2">{t("table.show")}:</span>
                <Form.Select
                  className="d-inline-block w-auto"
                  value={pageSize}
                  onChange={(e) => {
                    setPage(0);
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>

                <span className="ms-3 me-2">{t("table.goToPage")}:</span>
                <Form.Control
                  className="d-inline-block"
                  type="number"
                  max={numberOfPages}
                  defaultValue={page}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    setPage(page);
                  }}
                  style={{ width: "75px" }}
                />
              </Col>
              <Col md="4">
                <Pagination className="float-end">
                  <Pagination.First
                    onClick={() => {
                      setPage(1);
                    }}
                    disabled={page - 1 === 0}
                  />
                  <Pagination.Prev
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    disabled={page - 1 <= 0}
                  />
                  <Pagination.Next
                    onClick={() => {
                      setPage(page + 1);
                    }}
                    disabled={page >= numberOfPages}
                  />
                  <Pagination.Last
                    onClick={() => {
                      setPage(numberOfPages - 1);
                    }}
                    disabled={page === numberOfPages}
                  />
                </Pagination>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          onClick={() => setShowSessionModal(false)}
        >
          {t("simProfile.sessionModal.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimDataSessionsModal;
