import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

import dashboardItems from "../components/sidebar/dashboardItems";
import { useDispatch, useSelector } from "react-redux";
import { getWhitelabelDetails } from "../redux/slices/whitelabel";
import NotyfContext from "../contexts/NotyfContext";
import useAuth from "../hooks/useAuth";
import { useWhitelabel } from "../contexts/WhitelabelContext";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const Dashboard = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const impersonationUser = useSelector(
    (state) => state.impersonationUser.impersonationUser
  );
  const location = useLocation();
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const { loading } = useAuth();
  const {
    whitelabelLoading,
    error,
    whitelabelUrl,
    backgroundColor,
    fontColor,
  } = useWhitelabel();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchServerDetails = async () => {
      await dispatch(getWhitelabelDetails(Number(activeUser.id))).catch(
        (error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        }
      );
    };

    if (activeUser.id) {
      fetchServerDetails();
    }
  }, [activeUser, location]);

  if (loading || whitelabelLoading) {
    return (
      <div className="loading-overlay w-100 h-100 d-flex justify-content-center align-items-center">
        <Spinner
          animation="border"
          role="status"
          variant="primary"
          style={{
            "--dynamic-spinner-color": backgroundColor ?? "#00acdc",
          }}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Helmet
          titleTemplate={`%s | ${activeUser.domainCompany} - Connectivity Made Easy`}
        />
        <Sidebar items={dashboardItems} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
      {/* <Settings /> */}
    </React.Fragment>
  );
};

export default Dashboard;
